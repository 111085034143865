<template>
    <div class="footer">
        <div class="container information">
            <div class="block">
                <div class="f-tit">合作伙伴</div>
                <div class="f-content">广东横栏花木产业投资发展有限公司</div>
                <div class="f-content">中山市横栏农业发展有限公司</div>
                <div class="f-content">广东省苗仓网络科技有限公司</div>
                <div class="f-content">中国建设银行股份有限公司中山市分行</div>
                <div class="f-content">中国农业银行股份有限公司中山市分行</div>
            </div>
            <div class="block">
                <div class="f-tit">联系我们</div>
                <!-- <div class="f-content">中山市横栏镇三沙精品苗木展示区三丰三路5号</div>
                <div class="f-content">联系电话：400-838-6166</div> -->
                <div class="f-content">中山市横栏镇省级现代农业产业园花木产业公共技术创新平台附近</div>
                <div class="f-content">联系电话：19966280005</div>
                <div class="f-content">粤ICP备 16032228号</div>
            </div>
            <div class="block business">
                <div class="f-tit">商务合作</div>
                <!-- <div class="f-content">
                    <a href="#/service/index?t=1">供应商合作</a>
                </div>
                <div class="f-content">
                    <a href="#/service/index?t=2">采购商合作</a>
                </div>
                <div class="f-content">
                    <a href="#/service/index?t=3">物流合作</a>
                </div> -->
                <div class="f-content">
                  <a href="#/service/index?t=1">采购商合作：</a>
                  <br />
                  <a href="#/service/index?t=1">13420022414汤小姐</a>
                </div>
                <br />
                <div class="f-content">
                  <a href="#/service/index?t=2">供应商、物流商合作：</a>
                  <br />
                  <a href="#/service/index?t=2">19966280005侯先生</a>
                </div>
            </div>
            <div class="block">
                <div class="qr-box">
                    <img :src="qrGZH" alt="qrGZH">
                </div>
                <div class="f-content" style="width: 100%; text-align: center;">关注官方公众号</div>
            </div>
            <div class="block">
                <div class="qr-box">
                    <img :src="qrDownload" alt="qrDownload">
                </div>
                <div class="f-content" style="width: 100%; text-align: center;">下载苗仓APP</div>
            </div>
        </div>
    </div>
</template>

<script>
import imgSrc from '@/utils/img.js'
export default {
    name: 'Footer',
    data() {
        return {
            ...imgSrc.data()
        }
    }
}
</script>

<style lang="less">
.footer {
    width: 100%;
    height: 320px;
    background-color: #3C3C3C;
    .container {
        width: 1200px;
        margin: 0 auto;
    }
    .information {
        display: flex;
        justify-content: space-between;
        .block {
            .f-tit {
                font-size: 24px;
                color: #fff;
                margin: 40px 0 24px 0;
            }
            .f-content {
                font-size: 16px;
                color: #eee;
                padding-bottom: 6px;
                a {
                    text-decoration: none;
                    color: #eee;
                    &:active {
                        color: #eee;
                    }
                    &:hover {
                        color: #eee;
                    }
                }
            }
            .qr-box {
                width: 120px;
                height: 120px;
                background-color: #fff;
                margin: 40px 0 16px 0;
                img {
                    width: 108px;
                    height: 108px;
                    margin: 6px;
                }
            }
        }
    }
}
</style>