<template>
  <div class="home">
    <div class="banner">
      <el-image
        class="img"
        src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/banner.png"
        fit="cover"
      ></el-image>
      <div class="banner-container">
        <div class="banner-text">
          <!-- <div class="title">华南(中山)花木产业交易服务平台</div> -->
          <div class="title">华南（中山）花木产业线上交易平台</div>
          <!-- <div class="content">
            <p>
              华南(中山)花木产业交易服务平台是以专业、资深苗木专家团队为基础的大型资讯服务平台，致力于帮助华南地区苗木供应商更方便地了解华南花木政策及行业动态，同时帮助全国苗木采购商匹配真实可靠的苗木供应商。
            </p>
            <p>
              华南(中山)花木产业交易服务平台总部位于中山市横栏镇，“中国花木之乡”横栏镇产业经营面积近3万亩，从业人员超过2万人，基本形成了全方位的绿化苗木产销一体模式，品种1000多个，花木场2600多个，是华南地区主要的绿化苗木集散地，销往全国和东南亚地区，是引领中山花木产业发展的“佼佼者”.
            </p>
          </div> -->
          <div class="content">
            <p>
              华南（中山）花木产业线上交易平台是以专业、资深苗木专家团队为基础的大型资讯服务平台，致力于帮助华南地区苗木供应商更方便地了解华南花木政策及行业动态，同时帮助全国苗木采购商匹配真实可靠的苗木供应商。
            </p>
            <p>
              华南（中山）花木产业线上交易平台总部位于中山市横栏镇，“中国花木之乡”横栏镇产业经营面积近3万亩，从业人员超过2万人，基本形成了全方位的绿化苗木产销一体模式，品种1000多个，花木场2600多个，是华南地区主要的绿化苗木集散地，销往全国和东南亚地区，是引领中山花木产业发展的“佼佼者”。
            </p>
          </div>
        </div>
      </div>
    </div>
    <HeaderNav :active="navActive" @anchor="handleAnchor"></HeaderNav>
    <div class="container">
      <div class="main">
        <SectionTitle
          title="政务动态"
          more="Government"
          ref="Government"
        ></SectionTitle>
        <ImgTxtCard :data="topCardData"></ImgTxtCard>
        <TimeLine :list="govList"></TimeLine>
        <SectionTitle
          title="行业动态"
          more="Industry"
          ref="Industry"
        ></SectionTitle>
        <div class="card-list-s1">
          <el-card
            class="card-s1"
            v-for="(item, index) in industryList"
            :key="index"
            :body-style="{ padding: '0px' }"
          >
            <img :src="item.newsCover" class="image" />
            <div class="info">
              <div class="tit" @click="handleHangyeDetail(item.id)">
                {{ item.newsTitle }}
              </div>
              <div class="ctx">{{ item.newsAbstract }}</div>
              <div class="date">发布时间：{{ item.publishedTime }}</div>
            </div>
          </el-card>
        </div>
        <SectionTitle title="服务大厅" ref="Business"></SectionTitle>
        <ServeCardGroup></ServeCardGroup>
        <SectionTitle
          title="精品推荐"
          ref="Great"
        ></SectionTitle>
        <div class="spec-box">
          <div class="spec-title">品种：</div>
          <div class="spec-list">
            <div
              class="spec-item"
              v-for="(item, index) in goodsType"
              :key="index"
              :class="goodsTypeActive == item.id ? 'active' : ''"
              @click="handleGoodsType(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="goods-list">
          <GoodsCard
            v-for="(item, index) in goodsList"
            :key="index"
            :data="item"
          ></GoodsCard>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import ImgTxtCard from "@/components/ImgTxtCard.vue";
import ServeCardGroup from "@/components/ServeCardGroup.vue";
import TimeLine from "@/components/TimeLine.vue";
import GoodsCard from "@/components/GoodsCard.vue";
import Footer from "@/components/Footer.vue";
import imgSrc from "@/utils/img.js";
const demoIMG = imgSrc.data();

const goods = {
  seedlingPic: demoIMG.tree,
  seedlingName: "黄槐",
  stock: 200,
  spec: "胸径 5 - 7厘米 高度 3.5 - 4.5米",
  company: "中山市横栏镇某某某花木有限公司",
};
const goodsList = Array(8).fill(goods);

import { NewsList, findNewestSeedling, findNewestSeedling2 } from "@/api";
import { stringLength } from "@/utils";

export default {
  name: "Home",
  components: {
    HeaderNav,
    SectionTitle,
    ImgTxtCard,
    ServeCardGroup,
    TimeLine,
    GoodsCard,
    Footer,
  },
  data() {
    return {
      // cover: demoIMG.HANGYE1,
      navActive: "/",
      topCardData: {},
      govList: [],
      industryList: [],
      goodsType: [
        { id: "-1", name: "不限" },
        { id: "28", name: "时花" },
        { id: "26", name: "地被" },
        { id: "32", name: "草类" },
        { id: "25", name: "灌木" },
        { id: "23", name: "乔木" },
      ],
      goodsTypeActive: "28",
      goodsList,
    };
  },
  mounted() {
    document.body.style.backgroundColor = '#fff'
    NewsList({
      pageNum: 1,
      pageSize: 5,
      newsType: "1",
      // newsState: '1'
    })
      .then((res) => {
        let { content } = res.data;
        content.map((item) => {
          if (item.publishedTime)
            item.publishedTime = item.publishedTime.split(" ")[0];
          if (item.newsCover.indexOf("http") == -1) {
            item.newsCover = `http://img.miaocang.cc/${item.newsCover}`;
          }
          item.newsAbstract = stringLength(item.newsAbstract, 100);
          return item;
        });
        // 政务列表
        this.topCardData = { ...content[0] };
        this.govList = content;
        return NewsList({
          pageNum: 1,
          pageSize: 3,
          newsType: "2",
        });
      })
      .then((res) => {
        // 行业列表
        let { content } = res.data;
        this.industryList = content.map((item) => {
          if (item.publishedTime)
            item.publishedTime = item.publishedTime.split(" ")[0];
          if (item.newsCover.indexOf("http") == -1) {
            item.newsCover = `http://img.miaocang.cc/${item.newsCover}`;
          }
          item.newsAbstract = stringLength(item.newsAbstract, 80);
          return item;
        });
        this.getGoodsList('28')
      })
    window.addEventListener("scroll", this.handleHomeScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleHomeScroll, true);
  },
  methods: {
    getGoodsList(type='') {
      findNewestSeedling2({type}).then((res) => {
        let { data } = res;
        this.goodsList = data.map((item) => {
          let temp = "";
          item.standards.forEach((iitem, ii) => {
            if (ii != 0) {
              temp += "、";
            }
            temp += `${iitem.standardName}:${iitem.valueStart}~${iitem.valueEnd}${iitem.standardUnit}`;
          });
          item.spec = temp;
          return item;
        });
      });
    },
    handleHangyeDetail(id) {
      this.$router.push({
        name: "IndustryDetail",
        query: {
          article: id,
        },
      });
    },
    handleAnchor(point) {
      // console.log('govh', this.$refs.Government.$el.offsetTop)
      document.documentElement.scrollTop = this.$refs[point].$el.offsetTop - 40;
    },
    // 用于滚动同步导航
    handleHomeScroll() {
      let Government = this.$refs["Government"].$el.offsetTop - 40;
      let Industry = this.$refs["Industry"].$el.offsetTop - 40;
      let Business = this.$refs["Business"].$el.offsetTop - 40;
      let Great = this.$refs["Great"].$el.offsetTop - 40;
      let sct = document.documentElement.scrollTop;
      // console.log(document.documentElement.scrollTop)
      if (sct < Government) {
        this.navActive = "/";
      } else if (sct >= Government && sct < Industry) {
        this.navActive = "/gov/index";
      } else if (sct >= Industry && sct < Business) {
        this.navActive = "/industry/index";
      } else if (sct >= Business && sct < Great) {
        this.navActive = "/service/index";
      } else if (sct >= Great) {
        this.navActive = "#1";
      } else {
        this.navActive = "#2";
      }
    },
    handleGoodsType(item) {
      // console.log(item)
      let type = item.id === '-1' ? '' : item.id
      this.goodsTypeActive = item.id
      this.getGoodsList(type)
    },
  },
};
</script>

<style lang="less">
.home {
  .banner {
    width: 100%;
    height: 400px;
    .img {
      width: 100%;
      height: 400px;
    }
    .banner-container {
      width: 1200px;
      height: 400px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .banner-text {
      width: 900px;
      height: 225px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      .title {
        font-family: PangMenZhengDao;
        font-size: 60px;
        color: #fff;
        padding-bottom: 24px;
        background-image: -webkit-linear-gradient(bottom, #c5fff7, #fff);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .content {
        font-size: 14px;
        color: #fff;
        line-height: 1.5;
      }
    }
  }
  .main {
    margin-top: 30px;
    .card-list-s1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .card-s1 {
        width: 384px;
        &:hover {
          box-shadow: 0 0 30px #ccc;
          .tit {
            color: #20a162;
          }
        }
        .image {
          width: 100%;
          height: 240px;
          object-fit: fill;
        }
        .info {
          padding: 16px 24px;
        }
        .tit {
          height: 56px;
          font-size: 20px;
          color: #333;
          cursor: pointer;
        }
        .ctx,
        .date {
          font-size: 14px;
          color: #999;
        }
        .ctx {
          margin: 8px 0;
          height: 78px;
        }
      }
    }
    .spec-box {
      font-weight: 500;
      font-size: 20px;
      color: #666;
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .spec-title {
        // width: 100px;
      }
      .spec-list {
        // width: 1100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .spec-item {
        // height: 28px;
        // line-height: 28px;
        padding: 0 40px;
        cursor: pointer;
      }
      .spec-item + .spec-item {
        border-left: 1px solid #ccc;
      }
      .spec-item.active {
        color: #20a162;
        position: relative;
        &::before {
          content: "";
          width: 40px;
          height: 1px;
          background-color: #20a162;
          position: absolute;
          bottom: -3px;
          left: 40px;
        }
        &::after {
          content: "";
          width: 0px;
          height: 0px;
          border-top: 3px solid #20a162;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          position: absolute;
          bottom: -6px;
          left: 56px;
        }
      }
    }
    .goods-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
  .container {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
