import request from './request'

var jsonToFormData = (json) => {
  let formData = new FormData()
  for (let i in json) {
    if (json[i])
      formData.append(i, json[i])
  }
  return formData
}

// 获取动态清单
export function NewsList(query) {
  return request({
    url: '/web/newsInfo/findNewsInfoByPage',
    method: 'get',
    params: query
  })
}

// 根据id查询动态清单(详情)
export function NewsDetails(query) {
  return request({
    url: '/web/newsInfo/findNewsInfoById',
    method: 'get',
    params: query
  })
}

// 申请成为供应商(FormData)
export function ApplyToSupplier(data) {
  let ddata = jsonToFormData(data)
  return request({
    url: '/web/business/applyToSupplier',
    method: 'post',
    data: ddata,
    header: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 申请成为采购商(FormData)
export function ApplyToPurchaser(data) {
  let ddata = jsonToFormData(data)
  return request({
    url: '/web/business/applyToPurchaser',
    method: 'post',
    data: ddata,
    header: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 申请成为物流合作伙伴(FormData)
export function ApplyToLogistics(data) {
  let ddata = jsonToFormData(data)
  return request({
    url: '/web/business/applyToLogistics',
    method: 'post',
    data: ddata,
    header: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 获取最新发布的苗木(旧)
export function findNewestSeedling(query) {
  return request({
    url: '/web/seedling/findNewestSeedling',
    method: 'get',
    params: query
  })
}
// 获取最新发布的苗木
export function findNewestSeedling2(query) {
  return request({
    url: '/web/seedling/findNewestSeedlingWithMiaocangSearchApi',
    method: 'get',
    params: query
  })
}
// 获取最新创建的苗圃
export function findNewestWarehouse(query) {
  return request({
    url: '/web/supplierWarehouse/findNewestWarehouseWithMiaocangMallApi',
    method: 'get',
    params: query
  })
}
/**
 * 平台数据相关 
 * */
// 左栏
// 5.11. 根据品种类型获取总库存数前N的苗木(左边数据)
export function findTopInventoryMostSeedlingByType(query) {
  return request({
    url: '/web/miaocangStatistics/findTopInventoryMostSeedlingByType',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 弹出
// 5.12. 根据品种类型获取苗木品种数以及总库存数（和点击弹出的数据）
export function findKindOfNumAndTotalInventoryByType(query) {
  return request({
    url: '/web/miaocangStatistics/findKindOfNumAndTotalInventoryByType',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.13. 苗木规格数据分布
export function findSeedlingStandardInventory(query) {
  return request({
    url: '/web/miaocangStatistics/findSeedlingStandardInventory',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.10. 供应苗圃地区分布
export function findTopWarehouseMostAreaBySeedlingNumber(query) {
  return request({
    url: '/web/miaocangStatistics/findTopWarehouseMostAreaBySeedlingNumber',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.1. 获取苗木品种对应浏览量最多前N个地区
export function findTopViewNumMostAreaBySeedlingNumber(query) {
  return request({
    url: '/web/miaocangStatistics/findTopViewNumMostAreaBySeedlingNumber',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.2. 获取苗木品种对应电话数最多前N个地区
export function findTopCallNumMostAreaBySeedlingNumber(query) {
  return request({
    url: '/web/miaocangStatistics/findTopCallNumMostAreaBySeedlingNumber',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 中间栏
// 5.9 获取苗圃总数
export function findTotalWarehouseNum(query) {
  return request({
    url: '/web/miaocangStatistics/findTotalWarehouseNum',
    method: 'get',
    params: query,
    timeout: 30 * 1000
    // header: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    // }
  })
}
// 5.7获取苗木品种总数
export function findTotalSeedlingNum(query) {
  return request({
    url: '/web/miaocangStatistics/findTotalSeedlingNum',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.4获取库存总量
export function findTotalInventory(query) {
  return request({
    url: '/web/miaocangStatistics/findTotalInventory',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.8获取苗圃数最城市排名(饼图1)
export function findTopWarehouseMostArea(query) {
  return request({
    url: '/web/miaocangStatistics/findTopWarehouseMostArea',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.6获取苗木品种数排名(饼图2)
export function findTopSeedlingNumMostType(query) {
  return request({
    url: '/web/miaocangStatistics/findTopSeedlingNumMostType',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.3获取库存总量城市排名(饼图3)
export function findTopInventoryMostCity(query) {
  return request({
    url: '/web/miaocangStatistics/findTopInventoryMostCity',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 5.3获取华南地区各地级市苗圃数量
export function findSouthernChinaWarehouseNum(query) {
  return request({
    url: '/web/miaocangStatistics/findSouthernChinaWarehouseNum',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}
// 1.1. 直营店统计数据
export function showShopStatistics(query) {
  return request({
    url: '/web/directStatistics/showShopStatistics',
    method: 'get',
    params: query,
    timeout: 30 * 1000
  })
}

/**
 * 云展会相关
 */

/* 展会服务 - 列表 */
export function getServiceList(params) {
  return request({
    url: '/web/serve/serveList',
    method: 'get',
    params
  })
}

/* 云观展 - 列表 */
export function getExhibitionBannerList(params) {
  return request({
    url: '/web/cloudExhibition/exhibitionBannerList',
    method: 'get',
    params
  })
}

/* 云直播 - 列表 */
export function getLiveBannerList(params) {
  return request({
    url: '/web/cloudExhibition/liveBannerList',
    method: 'get',
    params
  })
}

/* 云直播 - 探花姐姐 - 列表 */
export function getFindFlowerSisterList(params) {
  return request({
    url: '/web/findFlowerSister/list',
    method: 'get',
    params
  })
}

/* 云直播 - 探花姐姐 - 详情 */
export function getFindFlowerSisterDetail(params) {
  return request({
    url: '/web/findFlowerSister/detail',
    method: 'get',
    params
  })
}

/* 展会服务 - 展商介绍 - 列表 */
export function getExhibitorList(params) {
  return request({
    url: '/web/exhibitorIntroduction/list',
    method: 'get',
    params
  })
}

/* 展会服务 - 展商介绍 - 详情 */
export function getExhibitorDetail(params) {
  return request({
    url: '/web/exhibitorIntroduction/detail',
    method: 'get',
    params
  })
}

// demo-get
// export function goodsDetails(query) {
//     return request({
//       url: '/wx/h5/goodsDetail',
//       method: 'get',
//       params: query
//     })
// }
// demo-post
// export function addCart(data) {
//   return request({
//     url: '/mall/cart/add',
//     method: 'post',
//     data
//   })
// }



