<template>
  <div class="goods-card">
    <el-image class="img" :src="data.seedlingPic" fit="cover"></el-image>
    <div class="info">
      <div class="top">
        <div class="goods-name">{{ data.seedlingName }}</div>
        <div class="total">{{ data.provinceName }}{{data.cityName}}</div>
      </div>
      <div class="spec">{{ data.spec }}</div>
      <div class="spec">
        <span>价格面议</span>
        <span>库存{{ data.stock }}</span>
      </div>
      <div class="company">{{data.enterpriseName}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsCard",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  methods: {
    handleGoodsDetails(sku) {
      window.open(`http://oow.miaocang.cc/goods/${sku}`)
    }
  }
};
</script>

<style lang="less">
.goods-card {
  width: 282px;
  height: 433px;
  border-radius: 4px;
  margin-bottom: 25px;
  box-shadow: 0 0 10px #e8e8e8;
  cursor: pointer;
  .img > img {
    border-radius: 4px 4px 0 0;
  }
  &:hover {
    box-shadow: 0 0 30px #ccc;
    .info > .top > .goods-name {
      font-size: 20px;
      color: #20a162;
    }
  }
  .img {
    width: 282px;
    height: 282px;
  }
  .info {
    margin: 16px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .goods-name {
        font-size: 20px;
        color: #333;
      }
      .total {
        font-size: 14px;
        color: #999;
      }
    }
    .spec {
      font-size: 14px;
      color: #666;
      margin: 8px 0;
      span:first-child {
        padding-right: 30px;
      }
    }
    .company {
      font-size: 14px;
      color: #999;
    }
  }
}
</style>