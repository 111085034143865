<template>
    <div class="card-group">
        <div class="txt-card" @click="handleService('Business')">
            <div class="topic">
                <img :src="demoIMG.business" alt="business">
                <span>商务合作</span>
            </div>
            <div class="desc">供应商、采购商、物流公司</div>
            <div class="desc">诚邀入驻</div>
        </div>
        <div class="txt-card" @click="handleService('贷款服务')">
            <div class="topic">
                <img :src="demoIMG.loan" alt="loan">
                <span>贷款服务</span>
            </div>
            <div class="desc">两大国有银行</div>
            <div class="desc">惠农贷款服务</div>
        </div>
        <div class="txt-card" @click="handleService('保险服务')">
            <div class="topic">
                <img :src="demoIMG.insurance" alt="insurance">
                <span>保险服务</span>
            </div>
            <div class="desc">中山市农业局政策性</div>
            <div class="desc">保险实施方案</div>
        </div>
        <div class="txt-card" @click="handleService('土地转让')">
            <div class="topic">
                <img :src="demoIMG.land" alt="land">
                <span>土地转让</span>
            </div>
            <div class="desc">中山农业土地转让服务</div>
            <div class="desc">敬请期待</div>
        </div>
        <el-dialog
            :visible.sync="visibleTips"
            width="30%"
            :before-close="handleClose">
            <span>{{typeName}}正在筹备中，敬请期待！</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleTips = false">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import imgSrc from '@/utils/img.js'
const demoIMG = imgSrc.data()

export default {
    name: 'ServeCardGroup',
    data() {
        return {
            demoIMG,
            // ...imgSrc.data()
            typeName: '',
            visibleTips: false
        }
    },
    methods: {
        handleClose() {
            this.visibleTips = false
        },
        handleService(type) {
            if (type === 'Business') {
                this.$router.push({
                    name: 'Business'
                })
            } else {
                this.typeName = type
                this.visibleTips = true
            }
        }
    }
}
</script>

<style lang="less">
.card-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .txt-card {
        width: 282px;
        height: 160px;
        background: linear-gradient(180deg, #EBF2F8 0%, #FFFFFF 100%);
        border-radius: 4px;
        border: 2px solid #FFFFFF;
        text-align: center;
        box-shadow: 0 0 10px #e8e8e8;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 30px #ccc;
          .topic {
            color: #20a162;
          }
        }
        .topic {
            margin-top: 48px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            img {
                width: 30px;
                height: 32px;
            }
            span {
                font-size: 20px;
                color: #333;
                font-weight: bold;
            }
        }
        .desc {
            font-size: 16px;
            color: #999;
        }
    }
}
</style>