<template>
    <div class="time-line">
        <div v-for="(item, index) in newList" :key="index">
            <div class="item" v-if="index!==0">
                <div class="date">{{item.publishedTime}}</div>
                <div class="right-info">
                    <div class="title" @click="toDetail(item.id)">{{item.newsTitle}}</div>
                    <div class="sub-tit">{{item.newsAbstract}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { stringLength } from '@/utils'
export default {
    name: 'TimeLine',
    props: {
        list: {
            type: Array,
            default: []
        }
    },
    computed: {
        newList() {
            // let newList = new Array()
            let _list = Object.assign(this.list)
            return _list.map(item => {
                item.newsAbstract = stringLength(item.newsAbstract, 50)
                return item
            })
        }
    },
    methods: {
        toDetail(id) {
            this.$router.push({
                name: 'GovDetail',
                query: {
                    article: id
                }
            })
        }
    }
}
</script>

<style lang="less">
.time-line {
    margin: 44px 0;
    .item {
        display: grid;
        grid-template-columns: 20% 80%;
        // justify-content: space-between;
        .date {
            width: 100%;
            height: 102px;
            text-align: right;
            line-height: 78px;
            font-size: 32px;
            color: #aaa;
            font-family: Bebas;
        }
        .right-info {
            width: 100%;
            height: 62px;
            padding: 20px 24px;
            border-bottom: 1px solid #eee;
            .title {
                font-size: 20px;
                color: #666;
                padding-bottom: 16px;
                cursor: pointer;
                &:hover {
                    color: #20a162;
                }
            }
            .sub-tit {
                font-size: 16px;
                color: #999;
                word-break:keep-all;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
            }
        }
    }
}
</style>