import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { scrollTo } from '@/utils/scrollTo'

Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cloud',
    name: 'CloudIndex',
    redirect: '/cloud/index',
    component: () => import(/* webpackChunkName: "CloudIndex" */ '../views/cloud/index.vue'),
    meta: {
      level: [''],
      title: '云展会',
      // 云展会相关页面, cloud传true
      cloud: true
    },
    children: [
      {
        path: 'index',
        name: 'Cloud',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Cloud" */ '../views/cloud/cloud.vue'),
        meta: {
          level: [''],
          title: '云展会',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'news/list',
        name: 'CloudNewsList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CloudNewsList" */ '../views/cloud/news/list.vue'),
        meta: {
          level: ['云资讯'],
          title: '云资讯',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'news/detail',
        name: 'CloudNewsDetail',
        component: () => import(/* webpackChunkName: "CloudNewsDetail" */ '../views/cloud/news/detail.vue'),
        meta: {
          level: ['云资讯', '详情'],
          title: '云资讯详情',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'exhibition/list',
        name: 'CloudExhibitionList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CloudExhibitionList" */ '../views/cloud/exhibition/list.vue'),
        meta: {
          level: ['云观展'],
          title: '云观展',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'exhibition/detail',
        name: 'CloudExhibitionDetail',
        component: () => import(/* webpackChunkName: "CloudNewsDetail" */ '../views/cloud/exhibition/detail.vue'),
        meta: {
          level: ['云观展', '图片展'],
          title: '云观展图片展',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'live/list',
        name: 'CloudLiveList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CloudLiveList" */ '../views/cloud/live/list.vue'),
        meta: {
          level: ['云直播'],
          title: '云直播',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'findFlowerSister/list',
        name: 'CloudFindFlowerSisterList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CloudFindFlowerSisterList" */ '../views/cloud/findFlowerSister/list.vue'),
        meta: {
          level: ['云直播', '探花姐姐'],
          title: '探花姐姐',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
      {
        path: 'exhibitor/list',
        name: 'CloudExhibitorList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "CloudExhibitorList" */ '../views/cloud/exhibitor/list.vue'),
        meta: {
          level: ['展商介绍'],
          title: '展商介绍',
          // 云展会相关页面, cloud传true
          cloud: true
        }
      },
    ]
  },
  {
    path: '/gov',
    name: 'GovIndex',
    redirect: '/gov/index',
    component: () => import(/* webpackChunkName: "GovIndex" */ '../views/gov/index.vue'),
    meta: {
      level: ['政务动态'],
      title: '政务动态'
    },
    children: [
      {
        path: 'index',
        name: 'Government',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Government" */ '../views/gov/Government.vue'),
        meta: {
          level: ['政务动态'],
          title: '政务动态'
        }
      },
      {
        path: 'detail',
        name: 'GovDetail',
        component: () => import(/* webpackChunkName: "GovDetail" */ '../views/gov/GovDetail.vue'),
        meta: {
          level: ['政务动态', '详情'],
          title: '政务详情'
        }
      },
    ]
  },
  {
    path: '/industry',
    name: 'IndustryIndex',
    redirect: '/industry/index',
    component: () => import(/* webpackChunkName: "IndustryIndex" */ '../views/industry/index.vue'),
    meta: {
      level: ['行业动态'],
      title: '行业动态'
    },
    children: [
      {
        path: 'index',
        name: 'Industry',
        component: () => import(/* webpackChunkName: "Industry" */ '../views/industry/Industry.vue'),
        meta: {
          level: ['行业动态'],
          title: '行业动态'
        }
      },
      {
        path: 'detail',
        name: 'IndustryDetail',
        component: () => import(/* webpackChunkName: "IndustryDetail" */ '../views/industry/IndustryDetail.vue'),
        meta: {
          level: ['行业动态', '详情'],
          title: '行业详情'
        }
      },
    ]
  },
  {
    path: '/service',
    name: 'ServiceIndex',
    redirect: '/service/index',
    component: () => import(/* webpackChunkName: "ServiceIndex" */ '../views/service/index.vue'),
    meta: {
      level: ['服务大厅'],
      title: '服务大厅'
    },
    children: [
      {
        path: 'index',
        name: 'Business',
        component: () => import(/* webpackChunkName: "Business" */ '../views/service/Business.vue'),
        meta: {
          level: ['服务大厅'],
          title: '服务大厅'
        }
      }
    ]
  },
  {
    path: '/platform',
    name: 'PlatformIndex',
    component: () => import(/* webpackChunkName: "PlatformIndex" */ '../views/platformData/index.vue'),
    meta: {
      level: ['平台数据'],
      title: '平台数据'
    }
  },
  /**
   * 平台数据(云展会2022版本)
   * Reference: https://docs.qq.com/doc/DQWNuZ3VuV2NEdEVN
   */
  {
    path: '/cloud/platform',
    name: 'CloudPlatformIndex',
    component: () => import(/* webpackChunkName: "PlatformIndex" */ '../views/cloud/platformData/index.vue'),
    meta: {
      level: ['平台数据'],
      title: '平台数据'
    }
  },
  /**服务大厅（手机端） */
  {
    path: '/mBusiness',
    name: 'mBusiness',
    component: () => import(/* webpackChunkName: "mBusiness" */ '../views/mobile/mBusiness.vue'),
    meta: {
      level: ['服务大厅'],
      title: '服务大厅'
    }
  },
  /* 移动端云资讯H5页(暂时不需要, 采用小程序原生页面方案) */
  {
    path: '/mNewsDetail',
    name: 'MobileNewsDetail',
    component: () => import(/* webpackChunkName: "MobileNewsDetail" */ '../views/mobile/news/detail.vue'),
    meta: {
      title: '云资讯'
    }
  },
  /* 移动端申请入驻招商H5页 */
  {
    path: '/mAttraction',
    name: 'DefaultAttraction',
    component: () => import(/* webpackChunkName: "DefaultAttraction" */ '../views/mobile/attraction/default.vue'),
    meta: {
      title: '申请入驻'
    }
  },
  /* 云展会展商详情H5页 */
  {
    path: '/cloud/exhibitor/detail',
    name: 'CloudExhibitorDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudExhibitorDetail" */ '../views/cloud/exhibitor/detail.vue'),
    meta: {
      level: ['展商介绍', '详情'],
      title: '展商详情',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会日程H5页 */
  {
    path: '/cloud/schedule',
    name: 'CloudExhibitionSchedule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudFindFlowerSisterList" */ '../views/mobile/cloudExhibition/schedule.vue'),
    meta: {
      level: ['云展会', '展会日程'],
      title: '展会日程',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会交通指南H5页 */
  {
    path: '/cloud/transportation',
    name: 'CloudExhibitionTransportation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudExhibitionTransportation" */ '../views/mobile/cloudExhibition/transportation.vue'),
    meta: {
      level: ['云展会', '交通指南'],
      title: '交通指南',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会交通指南H5页 - 2023 */
  {
    path: '/cloud/transportation-2023',
    name: 'CloudExhibitionTransportation2023',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudExhibitionTransportation2023" */ '../views/mobile/cloudExhibition/transportation-2023.vue'),
    meta: {
      level: ['云展会', '交通指南'],
      title: '交通指南',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会介绍H5页 */
  {
    path: '/cloud/introduction',
    name: 'CloudExhibitionIntroduction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudFindFlowerSisterList" */ '../views/mobile/cloudExhibition/introduction.vue'),
    meta: {
      level: ['云展会', '展会介绍'],
      title: '展会介绍',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会介绍H5页 - 2023 */
  {
    path: '/cloud/introduction-2023',
    name: 'CloudExhibitionIntroduction2023',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudExhibitionIntroduction2023" */ '../views/mobile/cloudExhibition/introduction-2023.vue'),
    meta: {
      level: ['云展会', '展会介绍'],
      title: '展会介绍',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会日程H5页 - 2023 */
  {
    path: '/cloud/schedule-2023',
    name: 'CloudSchedule2023',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudSchedule2023" */ '../views/mobile/cloudExhibition/schedule-2023.vue'),
    meta: {
      level: ['云展会', '展会日程'],
      title: '展会日程',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会敬请期待H5页 */
  {
    path: '/cloud/comingSoon',
    name: 'CloudExhibitionComingSoon',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudFindFlowerSisterList" */ '../views/mobile/cloudExhibition/comingSoon.vue'),
    meta: {
      level: ['云展会', '展会日程'],
      title: '展会日程',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会探花姐姐视频详情H5页 */
  {
    path: '/cloud/findFlowerSister/detail',
    name: 'CloudFindFlowerSisterDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CloudFindFlowerSisterList" */ '../views/cloud/findFlowerSister/detail.vue'),
    meta: {
      level: ['云直播', '探花姐姐', '详情'],
      title: '探花姐姐',
      // 云展会相关页面, cloud传true
      cloud: true
    }
  },
  /* 云展会 "探花游" 文旅攻略 H5页 */
  {
    path: '/flower-tour/guide',
    name: 'FlowerTourGuide',
    component: () => import(/* webpackChunkName: "FlowerTourGuide" */ '../views/mobile/flower-tour/guide.vue'),
    meta: {
      title: '"探花游" 文旅攻略'
    }
  },
  /* 云展会 "探花游" 景点介绍 H5页 */
  {
    path: '/flower-tour/introduction',
    name: 'FlowerTourIntroduction',
    component: () => import(/* webpackChunkName: "FlowerTourIntroduction" */ '../views/mobile/flower-tour/introduction.vue'),
    meta: {
      title: '"探花游" 景点介绍'
    }
  },
  /* 云展会 "探花游" 路线图 H5页 */
  {
    path: '/flower-tour/route',
    name: 'FlowerTourRoute',
    component: () => import(/* webpackChunkName: "FlowerTourRoute" */ '../views/mobile/flower-tour/route.vue'),
    meta: {
      title: '"探花游" 路线图'
    }
  },
  /* 云展会 "花木产业导购图" 首页 H5页 */
  {
    path: '/shopping-guide/index',
    name: 'ShoppingGuideIndex',
    component: () => import(/* webpackChunkName: "ShoppingGuideIndex" */ '../views/mobile/shopping-guide/index.vue'),
    meta: {
      title: '花木产业导购图'
    }
  },
  /* 云展会 "花木产业导购图" 路线页 H5页 */
  {
    path: '/shopping-guide/route',
    name: 'ShoppingGuideRoute',
    component: () => import(/* webpackChunkName: "ShoppingGuideRoute" */ '../views/mobile/shopping-guide/route.vue'),
    meta: {
      title: '花木产业导购图'
    }
  },
  { path: '*', redirect: '/cloud/index', hidden: true }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    scrollTo(0, 0)
  }
})

router.beforeEach((to, from ,next) => {
  const { path = '', fullPath = '' } = to
  // 兼容旧版本url hash mode
  if(fullPath.includes('/#')) {
    const path = fullPath.substring(2)
    next(path)
    return
  }
  // 正式环境百度统计
  if(process.env.NODE_ENV === 'production') {
    if(_hmt) {
      if (path) {
        _hmt.push(['_trackPageview', fullPath])
      }
    }
  }
  next()
})

export default router
