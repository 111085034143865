<template>
  <div class="header-nav">
    <div class="header-nav-div" :class="styleFixed ? 'fixed' : ''">
      <div class="nav-ctx">
        <el-menu
          :default-active="active"
          class="el-menu-reset"
          mode="horizontal"
          :router="routerMode"
          @select="handleSelect"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/cloud/index">
            <div @click="toCLoudIndexPage">云展会</div>
          </el-menu-item>
          <el-menu-item index="/gov/index">
            <div @click="anchor('Government')">政务动态</div>
          </el-menu-item>
          <el-menu-item index="/industry/index">
            <div @click="anchor('Industry')">行业动态</div>
          </el-menu-item>
          <el-menu-item index="/service/index">
            <div @click="anchor('Business')">服务大厅</div>
          </el-menu-item>
          <el-menu-item index="#1">
            <div @click="anchor('Great')">精品推荐</div>
          </el-menu-item>
          <el-menu-item index="#2">
            <div @click="partner">合作伙伴</div>
          </el-menu-item>
          <!-- <el-menu-item index="#3"> -->
          <el-menu-item>
            <div @click="toDataPage">平台数据</div>
          </el-menu-item>
          <!-- <el-menu-item disabled>demo</el-menu-item> -->
          <!-- 此处下拉
          <el-submenu index="3">
            <template slot="title">我的工作台</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
          -->
        </el-menu>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-input
        placeholder="请输入访问密码"
        v-model="password"
        show-password
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmPWD">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  props: {
    active: {
      type: String,
      default: "/",
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    fixedHeight: {
      type: Number,
      default: 400,
    },
  },
  // watch: {
  //   active() {
  //     if (this.active === 'Great' || this.active === 'Partner') {

  //     }
  //   }
  // },
  data() {
    return {
      routerMode: true,
      styleFixed: false,
      centerDialogVisible: false,
      password: "",
    };
  },
  mounted() {
    // console.log(this.$route.path)
    if (this.$route.name === "Home") {
      this.routerMode = false;
    }
    if (this.fixed) {
      window.addEventListener("scroll", this.handleScroll, true);
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
    },
    partner() {
      // console.log(document.documentElement.scrollHeight)
      // console.log(document.documentElement.scrollTop)
      document.documentElement.scrollTop =
        document.documentElement.scrollHeight;
    },
    handleScroll() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > this.fixedHeight) {
        // console.log(scrollTop);
        this.styleFixed = true;
      } else {
        this.styleFixed = false;
      }
    },
    anchor(point) {
      console.log("point", point);
      if (this.$route.name === "Home") {
        this.$emit("anchor", point);
      } else if (point === "Great") {
        // location.href = 'http://oow.miaocang.cc/search'
        window.open("http://oow.miaocang.cc/search");
      } else {
        if (this.$route.path === point) {
          return false;
        }
        this.$router
          .push({
            name: point,
          })
          .catch((err) => {});
      }
    },
    toDataPage() {
      // this.centerDialogVisible = true;
      // this.$router.push({
      //   name: "PlatformIndex",
      // });
      const routeData = this.$router.resolve({ name: 'PlatformIndex' })
      // const routeData = this.$router.resolve({ name: 'CloudPlatformIndex' })
      window.open(routeData.href, '_blank')
    },
    confirmPWD() {
      if (this.password === "mc") {
        this.$router.push({
          name: "PlatformIndex",
        });
      }
    },

    /* 跳转到云展会页 */
    toCLoudIndexPage() {
      const { fullPath = '' } = this.$route
      fullPath !== '/cloud/index' && this.$router.push({
        name: 'CloudIndex'
      })
    }
  },
};
</script>

<style lang="less">
.header-nav {
  width: 100vw;
  height: 62px;
  background-color: #fff;
  .header-nav-div {
    width: 100vw;
    background-color: #fff;
    height: 62px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.04);
    &.fixed {
      position: fixed;
      top: 0;
      z-index: 9;
    }
  }
  .nav-ctx {
    width: 1280px;
    margin: 0 auto;
  }
  .el-menu-reset {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-color: transparent;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-color: transparent;
    color: #20a162;
    font-weight: bold;
    position: relative;
    &::before {
      content: "";
      width: 32px;
      height: 4px;
      background-color: #20a162;
      border-radius: 2px;
      position: absolute;
      top: 46px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>