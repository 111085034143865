<template>
    <div class="card">
        <el-image
            class="img"
            :src="data.newsCover"
            fit="cover"
        ></el-image>
        <div class="card-info">
            <div class="title" @click="handleDetails(data.id)">{{data.newsTitle}}</div>
            <div class="text">{{data.newsAbstract}}</div>
            <div class="date">{{data.publishedTime}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        cover: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: {}
        }
    },
    methods: {
        handleDetails(id) {
            this.$router.push({
                name: 'GovDetail',
                query: {
                    article: id
                }
            })
        }
    }
}
</script>

<style lang="less">
.card {
    width: 1200px;
    height: 240px;
    background-color: #f3f3f3;
    box-shadow: 0 0 10px #e8e8e8;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    cursor: pointer;
    .img {
        width: 384px;
        height: 240px;
    }
    .card-info {
        width: 768px;
        padding: 24px;
        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; 
            font-size: 20px;
            color: #333;
            font-weight: bold;
            margin-bottom: 16px;
            &:hover {
                color: #20a162;
            }
        }
        .text {
            width: 100%;
            height: 128px;
            overflow: hidden;
            font-size: 16px;
            color: #666;
            margin-bottom: 16px;
            line-height: 1.5;
        }
        .date {
            font-size: 14px;
            color: #999;
        }
    }
}
</style>