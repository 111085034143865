import Vue from 'vue'
import {
  Card, Button, Select, Option, Loading, Image, Menu, Submenu, MenuItem, MenuItemGroup, Breadcrumb, BreadcrumbItem, Pagination, Dialog, Form, FormItem, Input, Avatar, Dropdown, DropdownMenu, DropdownItem, Icon, Drawer, Row, Col, Skeleton, SkeletonItem, Tabs, TabPane, Empty
} from 'element-ui';
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'

Vue.config.productionTip = false

Vue.use(Card).use(Button).use(Select).use(Option).use(Loading).use(Image).use(Menu).use(Submenu).use(MenuItem).use(MenuItemGroup).use(Breadcrumb).use(BreadcrumbItem).use(Pagination).use(Dialog).use(Form).use(FormItem).use(Input).use(Avatar).use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Icon).use(Drawer).use(Row).use(Col).use(Skeleton).use(SkeletonItem).use(Tabs).use(TabPane).use(Empty)

import 'lib-flexible/flexible'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
