// import Vue from 'vue';
import axios from 'axios'
import { Message, MessageBox, Loading } from 'element-ui'
import { IsPC } from "@/utils/index.js";
// Vue.use(Message, MessageBox, Loading);

// Message({
//   message: '欢迎',
//   duration: 1000
// })

import store from '@/store'
// import { getToken } from '@/utils/auth'
axios.defaults.withCredentials = true

/**
 *
 */
// 1添加loading效果
// function startLoading() {
//     store.commit('showLoading')
// }

// function endLoading() {
//     store.commit('hideLoading')
// }

// 2添加全局loading效果
let loading
function startLoading() {
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.05)'
  })
}

function endLoading() {
  loading.close()
}

// console.log(process.env)
// create an axios instance
// const baseURL = process.env.NODE_ENV === 'development' ? 'http://testdirectapi.miaocang.cc:8086' : process.env.VUE_APP_BASE_API
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASE_API,
//   baseURL,
  timeout: 10 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // console.log(config)
    // Do something before request is sent
    if (store.getters.token) {
      // 让每个请求携带token-- ['X-Mall-Admin-Token']为自定义key 请根据实际情况自行修改
    //   config.headers['X-Mall-Admin-Token'] = getToken()
    }
    startLoading()
    console.log(process.env.NODE_ENV)
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    endLoading()
    const res = response.data
    if (res.code !== 200) {
      if (IsPC()) {
        MessageBox.alert(res.msg, '错误', {
          confirmButtonText: '确定',
          type: 'error'
        })
      } else {
        console.log(res)
      }
      return Promise.reject(res.msg)
    }
    return response.data
  }, error => {
    endLoading()
    console.log('err' + error)// for debug
    Message({
      message: '连接超时，请联系系统管理员）',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })

export default service
