<template>
  <div class="section-title">
    <div class="title" :style="{ fontSize: size ? size + 'px' : '' }">
      {{ title }}
    </div>
    <div class="more" v-if="more !== ''" @click="handleMore">
      <span>查看更多</span><i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    more: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 24,
    },
  },
  methods: {
    handleMore() {
      console.log(this.more);
      if (this.more.indexOf("http") > -1) {
        window.open(this.more, '_blank');
      } else {
        this.$router.push({
          name: this.more,
        });
      }
    },
  },
};
</script>

<style lang="less">
.section-title {
  padding: 23px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 24px;
    color: #000;
  }
  .more {
    font-size: 16px;
    color: #999;
    cursor: pointer;
    &:hover {
      color: #20a162;
    }
  }
}
</style>