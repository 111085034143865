export function stringLength(string, length) {
    if (string.length >= length) {
        return string.substring(0, length) + '...'
    }
    return string
}
// export function copyObject(obj) {
//     var temp = {}
//     for (let i in obj) {
//         temp[i] = obj[i]
//     }
//     return temp
// }

export function dotNumber(str) {
    let _num = str.toString().split('.')
    let intNum = parseInt(_num[0])
    let floatNum = _num.length > 1 ? ('.' + _num[1].substring(0, 2)) : ''
    return (intNum || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + floatNum
}

export function detectZoom(owidth=1920) {
    let ratio = 0
    // let screen = window.screen
    // let ua = navigator.userAgent.toLowerCase();
    let scale = 1

    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    }
    else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
    }
    // else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    //     ratio = window.outerWidth / window.innerWidth;
    // }
    // return ratio;
    if (ratio !== 1) {
        // let ww = window.innerWidth | window.outerWidth
        let ww = document.body.clientWidth | document.body.offsetWidth
        scale = ww / owidth
    }
    console.log('scale', scale)
    return scale
}

export function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "Opera Mini", "BlackBerry"); // 暂时忽略iPad
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}