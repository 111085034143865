export default {
    data() {
        return {
            business: require('@/assets/home/icon-business.png'),
            insurance: require('@/assets/home/icon-insurance.png'),
            land: require('@/assets/home/icon-land.png'),
            loan: require('@/assets/home/icon-loan.png'),
            HANGYE1: require('@/assets/home/IMG-HANGYE1.png'),
            HANGYE2: require('@/assets/home/IMG-HANGYE2.png'),
            HANGYE3: require('@/assets/home/IMG-HANGYE3.png'),
            tree: require('@/assets/home/IMG-tree.png'),
            qrDownload: require('@/assets/qr-download.jpg'),
            qrGZH: require('@/assets/qr-gzh.jpg'),
            Vendor1: require('@/assets/service/icon-Vendor1.png'),
            Vendor2: require('@/assets/service/icon-Vendor2.png'),
            Vendor3: require('@/assets/service/icon-Vendor3.png'),
            purchaserBg1: require('@/assets/service/IMG-purchaser-bg1.png'),
            purchaserBg2: require('@/assets/service/IMG-purchaser-bg2.png'),
            VendorBg1: require('@/assets/service/IMG-Vendor-bg1.png'),
            VendorBg2: require('@/assets/service/IMG-Vendor-bg2.png'),
            truckBg1: require('@/assets/service/IMG-truck-bg1.png'),
            truckBg2: require('@/assets/service/IMG-truck-bg2.png'),
            truck1: require('@/assets/service/icon-truck1.png'),
            truck3: require('@/assets/service/icon-truck3.png')
        }
    }
}